import { useEffect, useMemo, useRef } from 'react';
import { SearchRequest } from 'app/modules/search/models';

import { INFINITE_SCROLL_LIMIT, OFFSET_DEFAULT } from 'app/shared/constants';

import { identityFunction } from 'app/shared/utils/function';
import { selectCensorshipFieldOptions } from 'app/modules/search/selectors';
import { censorshipFieldsSearch } from 'app/modules/search/actions';
import { useDispatch, useSelector } from 'react-redux';

import {
  U21SelectOptionProps,
  U21SelectSearch,
  U21SelectSearchProps,
} from 'app/shared/u21-ui/components';
import { IconClipboardList } from '@u21/tabler-icons';

interface Props {
  getPayload?: (payload: SearchRequest) => SearchRequest;
  selector?: (state: RootState) => U21SelectOptionProps<string>[];
}

export type U21CensorshipFieldSelectProps<TClearable extends boolean> =
  DistributiveOmit<
    U21SelectSearchProps<string, TClearable>,
    'options' | 'onSearch'
  > &
    Props;

export const U21CensorshipFieldSelect = <TClearable extends boolean>(
  props: U21CensorshipFieldSelectProps<TClearable>,
) => {
  const dispatch = useDispatch();
  const {
    getPayload = identityFunction,
    selector = selectCensorshipFieldOptions,
    ...rest
  } = props;

  const options = useSelector(selector);

  // assume array if undefined for missing values calculation
  const { value = [] } = props;
  const values = useMemo(
    () => (Array.isArray(value) ? value : [value]),
    [value],
  );

  // calledRef is used to prevent an infinite loop in case the API never returns with the missing value
  const calledRef = useRef(false);
  useEffect(() => {
    calledRef.current = false;
  }, [values]);

  const getPayloadRef = useRef(getPayload);
  getPayloadRef.current = getPayload;
  useEffect(() => {
    const missingValues = values.filter(
      (i) => !options.find((j) => j.value === i),
    );
    if (missingValues.length && !calledRef.current) {
      calledRef.current = true;
      dispatch(
        censorshipFieldsSearch(
          getPayloadRef.current({
            ids: missingValues,
            limit: missingValues.length,
            offset: OFFSET_DEFAULT,
            phrase: '',
          }),
        ),
      );
    }
  }, [dispatch, options, values]);

  return (
    <U21SelectSearch
      onSearch={(phrase = '') =>
        dispatch(
          censorshipFieldsSearch(
            getPayload({
              limit: INFINITE_SCROLL_LIMIT,
              offset: OFFSET_DEFAULT,
              phrase,
            }),
          ),
        )
      }
      options={options}
      startIcon={<IconClipboardList />}
      {...rest}
    />
  );
};
