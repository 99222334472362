import {
  FC,
  HTMLProps,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ENTITIES_TYPE_OPTIONS } from 'app/modules/search/constants';
import { INFINITE_SCROLL_LIMIT, OFFSET_DEFAULT } from 'app/shared/constants';
import permissions from 'app/shared/utils/permissions';
import styled from 'styled-components';

import { entitiesSearch } from 'app/modules/search/actions';
import { formatEntityOptions, ValueField } from 'app/modules/search/helpers';
import { EntitySearchRequest } from 'app/modules/search/models';
import {
  selectEntitiesSearchResults,
  selectEntitiesSearchLoading,
} from 'app/modules/search/selectors';
import { selectHasPermissionsFactory } from 'app/modules/session/selectors';
import { useSelector, useDispatch } from 'react-redux';

import { IconBuilding, IconSearch, IconUser } from '@u21/tabler-icons';
import {
  U21InputGroup,
  U21Select,
  U21SelectSearch,
  U21SelectProps,
  U21SelectSearchMultiProps,
} from 'app/shared/u21-ui/components';

export interface U21EntitySearchProps
  extends Omit<HTMLProps<HTMLDivElement>, 'as' | 'ref' | 'onChange' | 'value'> {
  disabled?: boolean;
  error?: boolean;
  clearable?: boolean;
  onChange: (value: (number | string)[], e: SyntheticEvent) => void;
  value?: (number | string)[];
  valueField?: ValueField;
  selectProps?: Omit<
    U21SelectProps<string, false>,
    'disabled' | 'error' | 'onChange' | 'options' | 'ref' | 'as'
  >;
  searchProps?: Omit<
    U21SelectSearchMultiProps<number>,
    | 'disabled'
    | 'error'
    | 'clearable'
    | 'onChange'
    | 'onSearch'
    | 'options'
    | 'ref'
    | 'value'
    | 'multi'
    | 'as'
  >;
}

const selectHasReadEntitiesPermissions = selectHasPermissionsFactory(
  permissions.readEntities,
);

export const U21EntitySearch: FC<U21EntitySearchProps> = ({
  disabled,
  error,
  clearable = true,
  onChange,
  value = [],
  valueField = ValueField.ID,
  selectProps = {},
  searchProps = {},
  ...rest
}) => {
  const dispatch = useDispatch();
  const { data } = useSelector(selectEntitiesSearchResults);
  const loading = useSelector(selectEntitiesSearchLoading);
  const hasReadEntitiesPermissions = useSelector(
    selectHasReadEntitiesPermissions,
  );

  const [type, setType] = useState(ENTITIES_TYPE_OPTIONS[0].value);

  const options = useMemo(
    () => formatEntityOptions(data, type, valueField),
    [data, type, valueField],
  );

  // calledRef is used to prevent an infinite loop in case the API never returns with the missing value
  const calledRef = useRef(false);
  useEffect(() => {
    calledRef.current = false;
  }, [value]);

  useEffect(() => {
    if (hasReadEntitiesPermissions) {
      const missingValues = value.filter(
        (i) => !options.find((j) => j.value === i),
      );
      if (missingValues.length && !calledRef.current) {
        calledRef.current = true;
        const searchPayload: EntitySearchRequest = {
          phrase: '',
          limit: missingValues.length,
          offset: OFFSET_DEFAULT,
        };
        if (valueField === ValueField.ID) {
          searchPayload.type = 'unit21_id';
          searchPayload.ids = missingValues;
        } else {
          searchPayload.type = 'external_id';
          searchPayload.external_ids = missingValues.map(String);
        }
        dispatch(entitiesSearch(searchPayload));
      }
    }
  }, [dispatch, hasReadEntitiesPermissions, options, value, valueField]);

  const onSearch = useCallback(
    (query) =>
      dispatch(
        entitiesSearch({
          limit: INFINITE_SCROLL_LIMIT,
          offset: OFFSET_DEFAULT,
          phrase: query || '',
          type,
        }),
      ),
    [dispatch, type],
  );

  if (!hasReadEntitiesPermissions) {
    return null;
  }

  return (
    <U21InputGroup disabled={disabled} error={error}>
      <StyledU21Select
        clearable={false}
        disabled={disabled}
        error={error}
        label="Search Entity"
        onChange={(val: string) => setType(val)}
        options={ENTITIES_TYPE_OPTIONS}
        searchable={false}
        startIcon={type === 'name' ? <IconBuilding /> : <IconUser />}
        value={type}
        {...selectProps}
      />
      <StyledU21SelectSearch
        disabled={disabled}
        error={error}
        clearable={clearable}
        limitTags={1}
        loading={loading}
        multi
        onChange={onChange}
        options={options}
        onSearch={onSearch}
        placeholder="Any"
        startIcon={<IconSearch />}
        value={value}
        {...rest}
        {...searchProps}
      />
    </U21InputGroup>
  );
};

const StyledU21Select = styled(U21Select)`
  min-width: 200px;
  width: 200px;
`;

const StyledU21SelectSearch = styled(U21SelectSearch)`
  min-width: 300px;
`;
