import { HTMLProps, MouseEvent, ReactElement, ReactNode, useMemo } from 'react';

import { getA11yClickProps } from 'app/shared/utils/a11y';
import { getDOMProps, isRenderable } from 'app/shared/utils/react';
import { useControlled } from 'app/shared/hooks/useControlled';
import styled, { css } from 'styled-components';

// Components
import { Card, CardHeader, CardContent, Collapse } from '@mui/material';
import { IconChevronRight } from '@u21/tabler-icons';
import {
  U21HelpModal,
  U21HelpModalProps,
} from 'app/shared/u21-ui/components/display/U21HelpModal';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';
import { U21TypographyIcon } from 'app/shared/u21-ui/components/display/typography/U21TypographyIcon';

export interface U21SectionProps
  extends Omit<HTMLProps<HTMLButtonElement>, 'action' | 'title'> {
  action?: ReactNode;
  children?: ReactNode;
  collapsed?: boolean;
  collapsible?: boolean;
  helpModalProps?: U21HelpModalProps;
  onToggleCollapse?: (collapsed: boolean, e: MouseEvent<HTMLElement>) => void;
  subheader?: ReactNode;
  title?: ReactNode;
  titleIcon?: ReactElement | null;
  mounted?: boolean;
}

// todo:
// - replace help modal with info alert

const getTitle = (title, helpModalProps) => {
  if (helpModalProps) {
    return (
      <U21Spacer horizontal>
        {title}
        <U21HelpModal {...helpModalProps} />
      </U21Spacer>
    );
  }

  return title;
};

export const U21Section = ({
  action,
  children,
  collapsed: collapsedProp = false,
  collapsible = false,
  helpModalProps,
  onToggleCollapse: onToggleCollapseProp,
  title,
  titleIcon,
  subheader,
  mounted = false,
  ...rest
}: U21SectionProps) => {
  const [collapsed, onToggleCollapse] = useControlled(
    collapsedProp,
    onToggleCollapseProp,
  );

  const hasTitle = Boolean(title);

  const icon = useMemo(() => {
    if (collapsible) {
      return (
        <CollapsibleTypographyIcon $collapsed={collapsed} variant="h4">
          <IconChevronRight />
        </CollapsibleTypographyIcon>
      );
    }
    if (titleIcon) {
      return <U21TypographyIcon variant="h4">{titleIcon}</U21TypographyIcon>;
    }
    return null;
  }, [collapsed, collapsible, titleIcon]);

  return (
    <StyledCard {...getDOMProps(rest)}>
      <Content>
        {hasTitle && (
          <StyledCardHeader
            $collapsible={collapsible}
            action={action}
            avatar={icon}
            title={getTitle(title, helpModalProps)}
            subheader={subheader}
            {...getA11yClickProps(
              onToggleCollapse
                ? (event: MouseEvent<HTMLElement>) =>
                    onToggleCollapse(!collapsed, event)
                : undefined,
              !collapsible,
            )}
          />
        )}
        {(() => {
          if (!isRenderable(children)) {
            return null;
          }
          if (collapsible) {
            return (
              <Collapse
                in={!collapsed}
                mountOnEnter={!mounted}
                unmountOnExit={!mounted}
              >
                <StyledCardContent $hasTitle>{children}</StyledCardContent>
              </Collapse>
            );
          }
          return (
            <StyledCardContent $hasTitle={hasTitle}>
              {children}
            </StyledCardContent>
          );
        })()}
      </Content>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  display: flex;
  max-width: 100%;
  padding: 0;
`;

const CollapsibleTypographyIcon = styled(U21TypographyIcon)<{
  $collapsed: boolean;
}>`
  transition: all ${(props) => props.theme.transitions.duration.standard}ms;
  ${(props) =>
    !props.$collapsed
      ? css`
          transform: rotate(90deg);
        `
      : css``}
`;

const Content = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`;

const StyledCardHeader = styled(CardHeader)<{ $collapsible?: boolean }>`
  padding: 24px;
  border-radius: ${(props) => props.theme.shape.borderRadiusMd}px;

  ${(props) =>
    props.$collapsible
      ? css`
          cursor: pointer;
        `
      : css``}

  .MuiCardHeader-title {
    text-transform: capitalize;
  }

  .MuiCardHeader-action {
    margin: 0;
  }
`;

const StyledCardContent = styled(CardContent)<{ $hasTitle?: boolean }>`
  flex: 1;

  padding-left: 24px;
  padding-right: 24px;

  // give higher specificity because CardContent applies 0 bottom padding to last child
  &&& {
    padding-bottom: 24px;
  }

  ${(props) =>
    !props.$hasTitle &&
    css`
      padding-top: 24px;
    `}
`;

U21Section.Content = Content;
