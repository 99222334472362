import {
  U21Alert,
  U21Modal,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { ReactElement } from 'react';
import { useControlled } from 'app/shared/hooks/useControlled';

interface ChildrenProps {
  onClick: () => void;
}

interface U21ExternalLinkModalPropsBase {
  url: string;
}

interface U21ExternalLinkModalPropsUncontrolled
  extends U21ExternalLinkModalPropsBase {
  children: (props: ChildrenProps) => ReactElement;
  onClose?: never;
  open?: never;
}

interface U21ExternalLinkModalPropsControlled
  extends U21ExternalLinkModalPropsBase {
  children?: never;
  onClose: () => void;
  open: boolean;
}

export type U21ExternalLinkModalProps =
  | U21ExternalLinkModalPropsUncontrolled
  | U21ExternalLinkModalPropsControlled;

export const U21ExternalLinkModal = (props: U21ExternalLinkModalProps) => {
  const {
    children,
    open: controlledOpen = false,
    onClose: controlledOnClose,
    url,
  } = props;
  const [open, setOpen] = useControlled(controlledOpen, controlledOnClose);
  return (
    <>
      {children?.({ onClick: () => setOpen(true) })}
      <U21Modal
        title="External Link Confirmation"
        open={open}
        onClose={(e) => {
          e.stopPropagation();
          setOpen(false);
        }}
        actionButtonProps={{ href: url }}
        onAction={(e) => {
          e.stopPropagation();
          setOpen(false);
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <U21Alert severity="warning">
          You are about to leave Unit21 and navigate to an external website:
          <br />
          <U21Typography color="inherit" ellipsis variant="subtitle2">
            {url}
          </U21Typography>
          <br />
          Please be aware that we do not control the content, security
          practices, or privacy policies of this third-party site.
        </U21Alert>
      </U21Modal>
    </>
  );
};
