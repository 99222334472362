import { selectHasReadEntitiesPermissions } from 'app/modules/session/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { U21Chip } from 'app/shared/u21-ui/components';
import { toggleSidebar } from 'app/modules/sidebar/slice';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import { isCmdCtrlClick } from 'app/shared/utils/keyboard';
import { ROUTES_MAP, openInNewTab } from 'app/shared/utils/routes';

export interface U21EntityChipProps {
  id: number | string;
  name: string;
}

export const U21EntityChip = (props: U21EntityChipProps) => {
  const dispatch = useDispatch();
  const { id, name } = props;
  const hasReadEntitiesPermission = useSelector(
    selectHasReadEntitiesPermissions,
  );
  return (
    <U21Chip
      onClick={
        hasReadEntitiesPermission
          ? (e) => {
              e.stopPropagation();
              // @ts-ignore TODO: improve event types sc-67809
              if (isCmdCtrlClick(e)) {
                openInNewTab(
                  ROUTES_MAP.entitiesId.path.replace(':id', String(id)),
                );
              } else {
                dispatch(
                  toggleSidebar({
                    type: SidebarComponentTypes.ENTITY,
                    data: { id },
                  }),
                );
              }
            }
          : undefined
      }
    >
      {name}
    </U21Chip>
  );
};
